@import "bootstrap";

html {
	height: 100%;
}
/*
img { margin-bottom: 24px; F
     margin-top: 17px; 
    border-radius:2%;
}
*/

body {
	padding-top: 70px;
	height: 100%;
}

figcaption {
    text-align: right;
    margin-top: -20px;
    margin-bottom: 20px;
}



/* Top navigation bar */

//.navbar{
//	height: 70px; /* Just an example height*/
//}
//.navbar-right{
//	margin-top: 10px;
//	height: 70px;
//}

p.byline {
	text-align: center;
	font-size: 18px;
	line-height: 36px;
	margin-top: -24px;
	margin-bottom: 48px;
}

pubtit {
	font-weight: bold;
	font-size: large;
}


.container-fluid {
  
  margin-right: auto;
  margin-left: auto;
  max-width: 1000px; /* or 950px */
}

/* Main Content */
.content{
	min-height: 100%; // necessary for keeping footer down
}

/* Home, News */
#newsid p {
	text-align: left;
}

#homeidtmp h1 {
	text-align: center;
}

#newsid img {
	margin-top: 6px;
	margin-bottom: 6px;
}

/* Memners & Publications */
#gridid img { margin: 16px 22px 6px 0; border-radius:10%; box-shadow: 2px 2px 5px #888888 }
#gridid .pub img { width: 20%; float: left; margin: 16px 22px 6px 0; border-radius:5%; box-shadow: none;}
@media only screen and (max-width: $screen-sm-min) {
	#gridid .pub img { width: 80%; position: relative; left: 10%; float: none; margin: 10px 0 10px 0; }
}
#homeid img { margin: 0 0 0 0; border-radius:5%; box-shadow: 0px 0px 0px #888888 }
#picid img { margin: 6px 3px 60px 6px; border-radius:1%; box-shadow: 2px 2px 5px #888888 }
#gridid .row { margin-bottom: 24px; }


/* Grid overrides */
.col-sm-1, .col-sm-2, .col-sm-3, .col-sm-5, .col-sm-6,
.col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12 {
	padding-left: 16px;
	padding-right: 16px;
}

/* Grid overrides */
.col-sm-4 {
	padding-left: 26px;
	padding-right: 26px;
}


/* Carusel */

.carousel {
    border-radius: 3px 3px 3px 3px;
    margin-bottom: 20px;
    margin-top: 20px;
    margin-left: 30px;
    margin-right: 30px;
}

/* Instructor for class info */
.instructor-section {
	display: inline-block;
	margin-right: 32px;
  }
  
.instructor {
	display: inline-block;
	text-align: center;
	margin-right: 24px;
  }

.instructor img {
	height: 136px;
	border-radius: 68px;
	vertical-align: middle;
  }

.ta {
	display: inline-block;
	text-align: center;
	margin-right: 24px;
  }

  .ta img {
	height: 136px;
	border-radius: 68px;
	vertical-align: middle;
}

.class_student {
	display: inline-block;
	text-align: center;
	margin-right: 24px;
  }

.class_student img {
	height: 300px;
	vertical-align: middle;
}

.section {
	margin-bottom: 48px;
  }

/* Workshop*/
.workshop_date {
	font-size: 16px;
	color: #16598B;
	margin-bottom: 24px;
}

.invited {
	margin-left: 150px;
}

.speakers img {
	margin-top: 20px;
	width: 136px;
	height: 136px;
	border-radius: 68px;
	float: left;
}

.remarks {
	margin-bottom: 30px;
}

.banners img {
	width: 300px;
	height: 70px;
	margin-right: 10px;
}



/* Main pic */
@mixin aspect-ratio($width, $height) {
	position: relative;
	  
	&:before {
	  display: block;
	  content: "";
	  width: 100%;
	  padding-top: ($height / $width) * 100%;
	}
	  
	> img {
		  position: absolute;
		  top: 0;
		  left: 0;
		  right: 0;
		  bottom: 0;
		  width: 100%;
		  height: 100%;
	}
  }
  
  // Styling
  
  section {
	  background: #ffffff;
	  padding: 10px 0;
  }
  
  .container {
	  width: 100%;
	  //max-width: 1044px;
	  margin: 0 0px;
	  padding: 0 0px;
  }
  
  .carousel {
	  display: block;
	  text-align: left;
	  position: relative;
	  margin-bottom: 22px;
	  
	  > input {
		  clip: rect(1px, 1px, 1px, 1px);
		  clip-path: inset(50%);
		  height: 1px;
		  width: 1px;
		  margin: -1px;
		  overflow: hidden;
		  padding: 0;
		  position: absolute;
		  &:nth-of-type(8):checked ~ .carousel__slides .carousel__slide:first-of-type { margin-left: -700%; }
		  &:nth-of-type(7):checked ~ .carousel__slides .carousel__slide:first-of-type { margin-left: -600%; }
		  &:nth-of-type(6):checked ~ .carousel__slides .carousel__slide:first-of-type { margin-left: -500%; }
		  &:nth-of-type(5):checked ~ .carousel__slides .carousel__slide:first-of-type { margin-left: -400%; }
		  &:nth-of-type(4):checked ~ .carousel__slides .carousel__slide:first-of-type { margin-left: -300%; }
		  &:nth-of-type(3):checked ~ .carousel__slides .carousel__slide:first-of-type { margin-left: -200%; }
		  &:nth-of-type(2):checked ~ .carousel__slides .carousel__slide:first-of-type { margin-left: -100%; }
		  &:nth-of-type(1):checked ~ .carousel__slides .carousel__slide:first-of-type { margin-left: 0%; }
		  
		  &:nth-of-type(1):checked ~ .carousel__thumbnails li:nth-of-type(1) { box-shadow: 0px 0px 0px 0px rgba(102, 102, 153, 0.5); }
		  &:nth-of-type(2):checked ~ .carousel__thumbnails li:nth-of-type(2) { box-shadow: 0px 0px 0px 0px rgba(102, 102, 153, 0.5); }
		  &:nth-of-type(3):checked ~ .carousel__thumbnails li:nth-of-type(3) { box-shadow: 0px 0px 0px 0px rgba(102, 102, 153, 0.5); }
		  &:nth-of-type(4):checked ~ .carousel__thumbnails li:nth-of-type(4) { box-shadow: 0px 0px 0px 0px rgba(102, 102, 153, 0.5); }
		  &:nth-of-type(5):checked ~ .carousel__thumbnails li:nth-of-type(5) { box-shadow: 0px 0px 0px 0px rgba(102, 102, 153, 0.5); }
		  &:nth-of-type(6):checked ~ .carousel__thumbnails li:nth-of-type(6) { box-shadow: 0px 0px 0px 0px rgba(102, 102, 153, 0.5); }
		  &:nth-of-type(7):checked ~ .carousel__thumbnails li:nth-of-type(7) { box-shadow: 0px 0px 0px 0px rgba(102, 102, 153, 0.5); }
		  &:nth-of-type(7):checked ~ .carousel__thumbnails li:nth-of-type(8) { box-shadow: 0px 0px 0px 0px rgba(102, 102, 153, 0.5); }
	}
  }
  
  .carousel__slides {
	  position: relative;
	  z-index: 1;
	  padding: 0;
	  margin: 0;
	  overflow: hidden;
	  white-space: nowrap;
	  box-sizing: border-box;
	  display: flex;
  }
  
  .carousel__slide {
	  position: relative;
	  display: block;
	  flex: 1 0 100%;
	  width: 100%;
	  height: 100%;
	  overflow: hidden;
	  transition: all 300ms ease-out;
	  vertical-align: top;
	  box-sizing: border-box;
	  white-space: normal;
	  
	  figure {
		  display: flex;
		  margin: 0;
	  }
	  
	  div {
		  @include aspect-ratio(3, 2);
		  width: 100%;
	  }
	  
	  img {
		  display: block;
		  flex: 1 1 auto;
		  object-fit: cover;
	  }
	  
	  figcaption {
		  align-self: flex-end;
		  padding: 10px 10px 0 10px;
		  flex: 0 0 auto;
		  width: 20%;
		  min-width: 150px;
	  }
	  
	  .credit {
		  margin-top: 1rem;
		  color: rgba(0, 0, 0, 0.5);
		  display: block;        
	  }
	  
	  &.scrollable {
		  overflow-y: scroll;
	  }
  }
  
  .carousel__thumbnails {
	  list-style: none;
	  padding: 0;
	  margin: 0;
	  display: flex;
	  
	  margin: 0 -10px;
	  
	  .carousel__slides + & {
		  margin-top: 40px;
	  }
	  
	  li {        
		  flex: 1 1 auto;
		  max-width: calc((100% / 6) - 20px);  
		  margin: 0 10px;
		  transition: all 300ms ease-in-out;
	  }
	  
	  label {
		  display: block;
		  @include aspect-ratio(1,1);
		  
					
		  &:hover,
		  &:focus {
			  cursor: pointer;
			  
			  img {
				  box-shadow: 0px 0px 0px 3px rgba(33, 22, 184, 0.5);
				  transition: all 300ms ease-in-out;
			  }
		  }
	  }
	  
	  img {
		  display: block;
		  width: 100%;
		  height: 100%;
		  object-fit: cover;
	  }
  }